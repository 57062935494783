/* You can add global styles to this file, and also import other style files */
@import "assets/sass/style.scss";
@import "./assets/sass/global.scss";

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

:root{
   
}

html,
body,
*{
    font-family: "Inter";
}
input:focus, select:focus{
  /* border-color: #222 !important; */
  border-color: #222;
  box-shadow: none !important;
}
.app-content {
  padding-bottom: 50px !important;
  
}
div#navbarSupportedContent{
  background: #78A985;
  opacity: 85%;
}
ul.navbar-nav.ms-auto.mb-2.mb-lg-0.align-items-center.gap-4 {
  margin-top: 0px;
}
.conn button {
  color: #f9c41c;
  padding: 5px 15px !important;
}
