.ngx-pagination {
    li a, .current {
      border-radius: 8px !important;
      // padding: 4.5px 12px !important;
      padding-top: 4.5px !important;
      padding-bottom: 4.75px !important;
      min-width: 30px !important;
      margin: 3.5px !important;
      text-align: center !important;
    }
  
  }

  .ngx-pagination .current{
    background: #F9C41C!important;
  }