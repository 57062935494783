//
// Theme style
@import "~@ng-select/ng-select/themes/default.theme.css";
//

// Initialize
@import "init";

// Components
@import "components/components";


// Layout
@import "layout/base/layout";
@import "layout/layout";

@import "ngx-toastr/toastr";
@import '~intl-tel-input/build/css/intlTelInput.css';
.d-none{
    display: none;
}
.hero {
    width: 100%;
    overflow: hidden;
}

.blone.btn{
    padding-left:20px;
}

@media (max-width: 768px) {
    .searchblock {
        width: 94% !important;
        min-width: 185px !important;
        left: 3% !important;
    }
    .blone {
        margin-bottom: 15px;
    }
}