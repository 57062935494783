//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
$primary:       									#009640;
// $primary-active:    								#179950;
$primary-active:    								#008d3c;
$primary-light:    								    #e6fff1;
$primary-light-dark:							    #212E48;
$primary-inverse:  									#fff6ee;

//secondary
$secondary:       									#F9C41C;
$secondary-active:    								#f3ba06;
$secondary-light:    								#e6fff1;
$secondary-light-dark:							    #212E48;
$secondary-inverse:  								#fff6ee;

// Success
$success:       								    #75CC68;
$success-active:    								#5FB751;
$success-light:    									#EEFBEC;
$success-light-dark:    							#1C3238;
$success-inverse:  									#FFFFFF;

// Info
$info:       									    #7239EA;
$info-active:    									#5014D0;
$info-light:    									#F8F5FF;
$info-light-dark:    								#2F264F;
$info-inverse:  									#FFFFFF;

// Danger
$danger:       									    #F1416C;
$danger-active:    									#D9214E;
$danger-light:    									#FFF5F8;
$danger-light-dark:                                 #3A2434;
$danger-inverse:  									#FFFFFF;

// Warning
$warning:       									#E78B2F;
$warning-active:    								#CE7317;
$warning-light:    									#FCF2E8;
$warning-light-dark:                                #392F28;
$warning-inverse:  									#FFFFFF;

// Border Radiues
$border-radius-sm:            						.55rem;
$border-radius:               						.85rem;
$border-radius-lg:            						1.5rem;
$border-radius-xl:                                  2.25rem;

// Box shadow
$box-shadow-xs: 									0px 12px 24px rgba(0, 0, 0, 0.04);
$box-shadow-xs-dark: 								0px 12px 24px rgba(0, 0, 0, 0.04);